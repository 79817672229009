.search_wrap {
    position: relative;
    display: inline-block;
    border: 1px solid #5e5e5e;
    color: #363636;
    border-radius: 15px;
    height: 26px;
    float: left;
    word-break: break-all;
}

.search_wrap.grayBorder {
    border: 1px solid #dde0e5;
}

.search_wrap:hover {
    color: #000000;
    border: solid 1px #e7eaf0;
}

.search_wrap.contentSchedule {
    position: relative;
    display: inline-block;
    border: 1px solid #5e5e5e;
    color: #363636;
    border-radius: 15px;
    height: 26px;
}

.search_wrap.contentSchedule input {
    background: #333c4e;
    color: #f4f6f8;
}

.search_wrap.contentSchedule input:hover {
    border: 1px solid #f4f6f8;
}

.search_wrap.contentSchedule .search_btn {
    background: #333c4e url('../../images/icon/search_normal.png') no-repeat 5px 5px !important;
}

input#contentDetailSearch, input#playlistDetailSearch, input#scheduleDetailSearch, input#eventDetailSchedule, input#messageDetailSchedule {
    padding-right: 45px;
}

.long_search_box input[type="search"] {
    width: 250px;
    font-size: 10px;
    padding: 0 25px 0 16px;
}

.search_wrap .search_detail_btn {
    position: absolute;
    right: 27px;
    top: 3px;
    border: 0;
    border-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 19px;
    height: 19px;
    background: #fff url('../../images/icon/search_detail_normal.png') no-repeat 0px 0px;
    text-align: center;
}
.search_wrap .search_detail_btn:hover {
    background: #fff url('../../images/icon/search_detail_hover.png') no-repeat 0px 0px;
}
.search_wrap .search_btn {
    position: absolute;
    right: 6px;
    top: 1px;
    border: 0;
    border-radius: 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 20px;
    height: 24px;
    background: #fff url('../../images/icon/search_normal.png') no-repeat 5px 7px;
    text-align: center;
}

input[type="search"] {
    height: 26px;
    float: left;
    border: 0;
    border-radius: 15px;
}
input {
    padding: 0 10px;
    border: 1px solid #dde0e5;
    border-radius: 5px;
    line-height: 26px;
    font-size: 12px;
    color: #686b72;
    height: 25px;
}
.search_detail_popup {
    z-index: 101;
    width: 500px;
    top: 37px;
    right: 20px;
    background: #fff;
    border: 1px solid #dde0e5;
    display: block;
    position: absolute;
}
.search_detail_popup .title {
    font-size: 13px;
    font-weight: bold;
    line-height: 47px;
    border-bottom: 1px solid #ebebeb;
    padding-left: 20px;
}
.search_detail_popup .clearfix button {
    border-radius: 5px;
    text-align: left;
}

.search_detail_popup table {
    margin:0 25px;
}
/*.search_detail_popup {position:absolute;right:21px;top:38px;background:rgba(0,0,0,0.15);padding-bottom:2px;}*/
.search_detail_popup.setting > div {border:1px solid #dde0e5;width:307px;}
/*.search_detail_popup > div {position:relative;width:500px;background:#fff;}
.search_detail_popup > div > span {position:absolute;top:-13px;right:30px;}*/
.search_detail_popup h3 {margin:0;font-size:13px;line-height:47px;border-bottom:1px solid #ebebeb;padding-left:20px;}
.search_detail_popup > div > .clearfix {border-bottom:1px solid #ebebeb;margin:0 25px;}

.search_detail_popup > div > .btn_wrap {width:100%;text-align:right;padding:20px 0;height:28px;}
.search_detail_popup > div > .btn_wrap button {margin-right:25px;}
.search_detail_popup.setting > div > .btn_wrap {width:100%;text-align:center;padding:20px 0;height:28px;}
.search_detail_popup.setting > div > .btn_wrap button {margin-right:0;}

.search_detail_popup table th {padding:5px 0 5px 15px;color:#8d8d8d;vertical-align:middle;}
.search_detail_popup table th:first-child {padding-left:0}
.search_detail_popup table tr.va_t th {vertical-align: top;padding-top:10px;}
.search_detail_popup table td {padding:5px 0 5px 15px;width:70%}

.search_detail_popup.mini {right:5px;top:38px;background:rgb(242,242,242);padding-bottom:2px;}
.search_detail_popup.mini .size {font-size:10px;color:#b8b8b8;display:block;margin-bottom:7px;}
.search_detail_popup.mini > div {width:265px;}
.search_detail_popup.mini > div > .clearfix {border:1px solid #dde0e5;padding:18px 15px 20px 15px;margin:0px;}
.search_detail_popup.mini > div > span {position:absolute;top:-11px;right:12px;}

.search_detail_popup .searchButtonWrap {
    height: 28px;
    position: relative;
    display: inline-block;
    margin-right: 25px;
    margin-left: 25px;
    width: 450px;
    padding: 20px 0;
    border-top: 1px solid #ebebeb;
    text-align: right;
}

.search_detail_popup .option_list {
     width: 209px;
     position:absolute;
     top:37px;
     left:0;
     background:#fff;
     border:1px solid #dde0e5;
     box-shadow:#dedede 0px 1px 0px;
     z-index:1
}
.search_detail_popup .option_list > div {position:relative;}
.search_detail_popup .option_list > div > span {display:block;width:100%;height:8px;position:absolute;top:-8px;background:url('../../images/bg/option_list_bg.png') no-repeat 10px 0;}
.search_detail_popup .option_list > div > ul {
    overflow-y: auto;
    height: 200px;
}
.search_detail_popup .option_list > div > ul > li {padding:6px 5px 4px 13px;font-size:12px;position:relative;line-height:15px;text-align:left}
.search_detail_popup .option_list > div > ul > li:first-child {padding-top:11px}
.search_detail_popup .option_list > div > ul > li:last-child {border-bottom:0;padding-bottom:10px}
.search_detail_popup .option_list > div > ul > li.last {border-top:1px solid #DDE0E5;width:132px;border-bottom:0;padding-bottom:10px;}
.search_detail_popup .option_list > div > ul > li > a {display:inline-block;color:#707070;text-decoration: none;width:100%;font-size:12px;vertical-align:middle;}
.search_detail_popup .option_list > div > ul > li > a img {vertical-align:top;margin-right:8px;}
.search_detail_popup .option_list > div > ul > li.on > a {color:#3196ff !important;}
.search_detail_popup .option_list > div > ul > li > a:hover {color:#609be8 !important;cursor:pointer;}
.search_detail_popup .option_list > div > ul > li > a:active {color:#2789f4 !important;}

.search_detail_popup .search_detail_date input{
    position: relative;
}

.search_detail_popup .search_detail_calendar {
    position: relative;
    top: 6px;
    right: 35px;
    width: 22px;
    height: 22px;
    background: url('../../images/icon/calander_icon_normal.png');
    border: 0;
}

.search_detail_popup .base.un_radius.select:disabled {color: #c1c1c1; border-color: #f3f3f3; background: #f3f3f3; cursor: default;}


.search_detail_popup #searchDetailPopup > div > .chip_wrap {
    float:left;
}

.search_detail_popup .text_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
}

[data-tip] {
    position:relative;

}
[data-tip]:before {
    content:'';
    /* hides the tooltip when not hovered */
    display:none;
    content:'';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #1a1a1a;
    position:absolute;
    top:30px;
    left:35px;
    z-index:10;
    font-size:0;
    line-height:0;
    width:0;
    height:0;
}
[data-tip]:after {
    display:none;
    content:attr(data-tip);
    position:absolute;
    top:35px;
    left:0px;
    padding:5px 8px;
    background:#ffffff;
    color:#000000;
    z-index:11;
    font-size: 0.75em;
    height:18px;
    line-height:18px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space:nowrap;
    word-wrap:normal;
    border: 1px solid #5e5e5e;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
    display:block;
}