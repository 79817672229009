.ldap_contents {
    width: 100%;
    display: inline-flex;
    flex-flow: column;
    position: relative;
    min-height: 126px;
}
.ldap_contents_name {
    width: 100%;
    height: 27px;
    display: inline-flex;
    flex-flow: row;
    line-height: 27px;
}
.ldap_contents_title {
    width: 128px;
    font-size: 12px;
    color: #8d8d8d;
    font-weight: normal;
}
.ldap_contents_search_btn {
    background: url(../../../images/icon/search_select.png) no-repeat 6px 6px;
    display: inline-block;
    height: 27px;
    width: 27px;
}
.ldap_contents_search_btn_disable {
    background: url(../../../images/icon/search_circle_normal.png) no-repeat 6px 6px;
    display: inline-block;
    height: 27px;
    width: 27px;
}
.ldap_contents_detail {
    width: 100%;
}
.ldap_contents_detail_btn {
    border: 0;
    background: none;
    color: #8d8d8d;
    line-height: 28px;
    padding: 0;
    text-align: left;
    outline:none;
    cursor: pointer;
}
.ldap_contents_detail_btn > span {
    width: 7px;
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px;
}
.ldap_contents_detail_btn_open {
    background: url(../../../images/button/select_down.png) no-repeat;
    width: 7px;
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px;
}
.ldap_contents_detail_btn_close {
    background: url(../../../images/button/select_up.png) no-repeat;
    width: 7px;
    height: 5px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 9px;
}
.ldap_contents_notification {
    max-height: 230px;
    overflow-y: auto;
}
.ldap_contents_notification_subject {
    width: 100%;
    color: #8d8d8d;
    font-weight: normal;
    font-size: 12px;
    line-height: 38px;
}
.ldap_contents_notification_row {
    width: 100%;
    height: 38px;
    display: inline-flex;
    flex-direction: row;
}
.ldap_contents_notification_title {
    width: 310px;
    color: #8d8d8d;
    padding: 0px 10px;
    font-weight: normal;
    font-size: 12px;
    line-height: 38px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ldap_contents_notification_checkbox {
    line-height: 38px;
}
.ldap_content_approval {
    margin-top: 5px;
    display: block;
}
.ldap_content_approval_error {
    width: 100%;
    color: #f79394;
    line-height: 13px;
}
.ldap_device_permission {
    display: table;
    width: 100%;
}
.ldap_contents_detail table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}
.ldap_contents_detail table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}
.ldap_device_permission_device_permission {
    width: 230px;
    float: left;
    margin-right: 10px;
}
.ldap_device_permission_device_permission_list {
    height: 50px;
    width: 90%;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    padding: 10px;
    overflow-y: auto;
}
.ldap_device_permission_device_permission_row_del_button {
    height: 19px;
    width: 19px;
    background: url(../../../images/img/tab_close_normal.png) no-repeat 0 0;
    border: 0;
    padding: 0;
    margin: 0;
}