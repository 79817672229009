.datalink_detail_view .dl_table {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    width: 100%;
}

.datalink_detail_view .dl_table .tr .th{
    width: 143px;
}

.datalink_detail_view {
    position: relative;
}

.datalink_detail_view table th {
    vertical-align: middle;
    color: #8d8d8d;
    padding-right: 10px;
}

.datalink_detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}

.datalink_detail_view .icon {
    height: 24px;
    /*width: 100px;*/
    display: block;
    float: left;
    margin: 5px;
    cursor: pointer;
    background-size: cover;

}

.datalink_detail_view table th {
    color: #8d8d8d;
}

.datalink_detail_view caption, th, td {
    text-align: left;
    vertical-align: top;
    font-weight: normal;
    white-space: nowrap;
}
.datalink_detail_view table {
    width: 100%;
}
.datalink_detail_view table th {
    vertical-align: middle;
    color:
            #8d8d8d;
    padding-right: 10px;
}
.datalink_detail_view table td {
    padding: 6px 0 5px 0;
    vertical-align: middle;
    color: #474747;
    height: 28px;
}
.datalink_detail_view table td label {
    color: #474747;
    display: inline-block;
}
.datalink_detail_view input[type="checkbox"] + label {
    display: inline-block;
    padding-left: 24px;
    color: #59616b;
    background: url('../../../../images/img/checkbox_normal.png') no-repeat;
    padding-bottom: 2px;
    cursor: pointer;
}
/*../../images/img/checkbox_normal.png*/

.base_datalink {
    height: 28px;
    border: 1px solid #dde0e5;
    color: #363636;
    background: #fff;
    line-height: 24px;
    border-radius: 15px;
    text-align: center;
    width: 28px;
    margin-left: 2px;
}


.select_view_assign_table_list table tr td {height:20px; line-height:20px;padding:10px;}
.select_view_assign_table_list table tr td label {height:20px; line-height:20px;}

.assign_table_list {overflow:auto;}
.assign_table_list table tr td {border-right: 1px solid #d0d3d8; border-bottom:1px solid #d0d3d8;vertical-align:middle;text-align:left;padding:9px 10px 9px 10px;line-height:20px;color:#797979;}
.assign_table_list table tr:last-child td {border-bottom: 1px solid #d0d3d8;}
.assign_table_list table.table_check tr td {text-align:left; border-color:#d0d3d8;line-height:15px;}
.assign_table_list table.table_check tr td.on {background:url('../../../../images/img/thumb_check.png');background-repeat: no-repeat;background-position: center center;background-size: 15px 15px;border: 1px solid #d0d3d8; background-color: #e9f3fd;}
.assign_table_list table tr th > .data_name > label {text-indent:0px !important; cursor:pointer;white-space: nowrap;overflow: hidden;display: block;width:90px;text-overflow:ellipsis;font-size:10px;line-height:20px;display:-webkit-box;-webkit-line-clamp:3;-webkit-box-orient:vertical;}
.assign_table_list table tr th > .serviceName {white-space: nowrap;overflow: hidden;display: block;width:90px;text-overflow:ellipsis;}
.assign_table_list table tr th:first-child {padding:7px 0 7px 11px;}
.assign_table_list table tr th {
    color: #797979;
    vertical-align: middle;
    padding: 7px 10px 7px 10px;
    border-top: 1px solid #d2d2d3;
    border-bottom: 1px solid #d2d2d3;
    /*border-left: 1px solid #d2d2d3;
    border-right: 1px solid #d2d2d3;*/
    background-color: #eeeeef;
    text-overflow:ellipsis;
    background: url(../../../../images/bg/table_divider.png) no-repeat right #eeeeef;
}
.assign_table_list table tbody tr:first-child td.on {border-top: 0px}
.assign_table_list table tbody td.on:first-child {border-left: 0px}
.assign_table_list table tr td.on .assignCheck {top:0px; left:0px; width:100%; height:38px;background-color:#3297FF;opacity:0.1}
.assign_table_list table tr td input {width: 0; height: 0; margin:0; display: none;}
.assign_table_list .org_list {float:left;min-width:202px; height:450px; overflow:hidden;}
.assign_table_list .assign_table_header {height:98px;border-top:1px solid #d2d2d3;border-right:1px solid #d2d2d3;border-bottom:1px solid #d2d2d3;}
.assign_table_list .assign_table_header th {color:#797979;vertical-align:middle;border-top:1px solid #d2d2d3;border-bottom:1px solid #d2d2d3;border-left:0;}
.assign_table_list .assign_table_header th span {float:left !important;white-space:nowrap;overflow:hidden;display:block;text-overflow:ellipsis;width:98px;}
.assign_table_list .assign_table_body {position:relative;height:368px;}
.assign_table_list .assign_table_body > div {position: absolute; overflow: hidden; width: 202px; transform: translate3d(0px, 0px, 0px);}
.assign_table_list .assign_table_body > div > table {table-layout:fixed;width:100%; background-color:#F5F5F5;}
.assign_table_list .data_table_list {float:left;height:100%;max-width:926px;overflow-x:hidden;}
#assign_table td.on {background: url('../../../../images/img/thumb_check.png'); background-repeat:no-repeat; background-position: center center; background-size:15px 15px; border:1px solid #d0d3d8; background-color:#e9f3fd;}
/*#assign_table td {width:130px;height:20px}*/
.filterByIcon {
    height:16px;
    width:16px;
    vertical-align:middle;
    display:inline-block;
    background: url('../../../../images/icon/icon_filterby.png') no-repeat -2px 0;
}
.assign_table_list .org_list .checkboxWrap>label {
    overflow:hidden;
    width:160px;
    display:inline-block;
    line-height: 17px;
    height:13px;
    text-overflow:ellipsis;
}
.datalink_assign_org_td{
    width:350px !important;
    text-align:left !important;
    background-color:#eeeeef !important;
    border-top: 1px solid #d2d2d3 !important;
    border-bottom: 1px solid #d2d2d3 !important;
    border-left: 1px solid #d2d2d3 !important;
    border-right: 1px solid #d2d2d3 !important;
    text-overflow:ellipsis !important;
}
.datalink_assign_org_list table td{
    border: 1px solid grey
}