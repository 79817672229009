.device_list_view_wrap .device_tr_group {
    max-height: 140px;
}

.device_list_view_wrap .device_tr_group .rt-td {
    max-height: 140px;
}

.device_list_view_wrap .normal {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: url('../../images/icon/icon_tab_normal2.png') no-repeat;
}

.device_list_view_wrap .error {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: url('../../images/icon/icon_tab_error2.png') no-repeat;
}

.device_list_view_wrap .cabinet_monitoring:first-child {
    /*margin-left: 15px;*/
}

.device_list_view_wrap .cabinet_monitoring {
    float: left;
    text-align: center;
    width: 99px;
    height: 99px;
    line-height: 99px;
    /* background-color: #747f8a; */
    /* color: #252525; */
    margin-top: 15px;
    margin-right: 5px;
    border: solid white 1px;
    font-size: 14pt;
    background-color: #464646;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
}

.device_list_view_wrap .cabinet_monitoring.on {
    outline: solid #3196ff 2px;
}

.device_list_view_wrap .cabinet_monitoring > span {
    line-height: 0px;
    display: table;
    margin-left: 5px;
    margin-top: -93px;
}

.device_list_view_wrap .cabinet_left_wrap {
    width: calc(100% - 430px);
    height: 100%;
    padding: 30px;
    overflow: auto;
}

.device_list_view_wrap .cabinet_right_wrap {
    height: 100%;
    min-width: 350px;
    font-size: 13px;
    padding: 8px 28px 8px 28px;
    background-color: #f3f3f3;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 124px;
}
.device_list_view_wrap .outdoor_cabinet_right_wrap {
    height: 80%;
    min-width: 350px;
    font-size: 12px;
    padding: 8px 28px 0px 28px;
    background-color: #f3f3f3;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 160px;
}
.device_list_view_wrap .outdoor_cabinet_right_wrap .cabinet_info_title {
    font-weight: bold;
    margin: 0;
    color: #676767;
    height: 56px;
    line-height: 50px;
}

.device_list_view_wrap .outdoor_cabinet_right_wrap .cabinet_info_table {
    display: table;
    width: 100%;
}

.device_list_view_wrap .outdoor_cabinet_right_wrap .cabinet_info_table > div {
    display:table-row;
}

.device_list_view_wrap .outdoor_cabinet_right_wrap .cabinet_info_table > div > .cabinet_detail_label {
    display: table-cell;
    background-color: rgba(242, 242, 242, 1);
    height: 34px;
    width: 50px;
    padding-left: 5px;
    color: #000000;
}

.device_list_view_wrap .outdoor_cabinet_right_wrap .cabinet_info_table > div > .cabinet_detail_value {
    display: table-cell;
    text-align: right;
    width: 88px;
    height: 30px;
    line-height: 25px;
    padding-left: 16px;
    color: #474747;
    opacity: 1;
}

.device_list_view_wrap .outdoor_cabinet_right_wrap .horizontal_line {
    border: 1px solid #636363;
    margin: 29px 0 18px 0;
    opacity: 0.13;
}

.device_list_view_wrap .outdoor_cabinet_right_wrap .device_loading_wrap {
    position: absolute;
    width: 340px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}
.device_list_view_wrap .cabinet_right_wrap .cabinet_info_title {
    font-weight: bold;
    margin: 0;
    color: #676767;
    height: 56px;
    line-height: 50px;
}

.device_list_view_wrap .cabinet_right_wrap .cabinet_info_table {
    display: table;
    width: 100%;
}

.device_list_view_wrap .cabinet_right_wrap .cabinet_info_table > div {
    display:table-row;
}

.device_list_view_wrap .cabinet_right_wrap .cabinet_info_table > div > .cabinet_detail_label {
    display: table-cell;
    background-color: rgba(242, 242, 242, 1);
    height: 34px;
    width: 50px;
    padding-left: 5px;
    color: #000000;
}

.device_list_view_wrap .cabinet_right_wrap .cabinet_info_table > div > .cabinet_detail_value {
    display: table-cell;
    text-align: right;
    width: 88px;
    height: 30px;
    line-height: 25px;
    padding-left: 16px;
    color: #474747;
    opacity: 1;
}

.device_list_view_wrap .cabinet_right_wrap .horizontal_line {
    border: 1px solid #636363;
    margin: 29px 0 18px 0;
    opacity: 0.13;
}

.device_list_view_wrap .cabinet_right_wrap .device_loading_wrap {
    position: absolute;
    width: 340px;
    height: 100%;
    display: flex; 
    justify-content: center; 
    align-items: center;
    z-index: 1;
}

.cabinet_group_wrapper {
    padding: 0 20px;
}

.block_group_wrapper {
    padding: 0 20px;
}